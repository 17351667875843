<template>
<v-container>
</v-container>
</template>

<script>
  export default {
    components:{
      //
    },
    props:[
      'role',
    ],
    data: () => ({
      //
    }),
    created() {
      this.$router.replace(this.$_getRouteBrowse(this.role,this.$route.params.object))
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>